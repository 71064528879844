import * as v from 'app/forms/control/form-validators';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { groupStructure } from 'app/forms/structure/form-group.structure';
import { fieldStructure } from 'app/forms/structure/form-field.structure';
import { AutocompleteResult } from 'shared/components/autocomplete/autocomplete.component';
import { Culture } from 'app/i18n/culture.actions';

const emptyAutocompleteResult: AutocompleteResult<null> = {id: null, title: '', value: null};
const resultSelected = v.createValidator<AutocompleteResult<any>>('resultSelected', value => {
  return Boolean(value && value !== null);
});

interface CultureSelectFormValues {
  country: GlobalizationCountry;
  locale: GlobalizationCultureLanguage;
}

export class CultureSelectForm extends NavCarrierFormGroup<CultureSelectFormValues> {
  constructor() {
    super(groupStructure({
      country: fieldStructure(emptyAutocompleteResult),
      locale: fieldStructure(emptyAutocompleteResult)
    }));

    this.get('country').addValidator(resultSelected);
    this.get('locale').addValidator(resultSelected);

    this.get('locale').disable();
    this.get('country').validationChanges.subscribe(({valid}) => {
      valid ? this.get('locale').enable() : this.get('locale').disable();
    });
  }

  setCulture(culture: Culture) {
    const locale = (culture?.locale) || {
        countryCode: 'US',
        displayName: 'English',
        locale: 'en-US',
      };
    this.get('country').setValue(culture?.country);
    this.get('locale').setValue(locale);

    if (culture?.country.locales.length === 1) {
      this.get('locale').disable();
    }
  }

  toCulture(): Culture {
    return new Culture(this.value.country, this.value.locale);
  }
}
